import React, { useCallback, useRef, useMemo } from 'react'
import ReactSelect, {
  Props,
  OnChangeValue,
  MultiValue,
  SingleValue,
  components,
  DropdownIndicatorProps,
  ClearIndicatorProps,
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import cx from 'clsx'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'

import Tooltip from '@src/components/Tooltip'
import { QuestionMarkCircleIcon } from '@heroicons/react/outline'
import { IconChevronDown, IconX } from '@tabler/icons-react'
import { getSelectStyles } from './utils'

export { useSelectHelpers } from './hooks/useSelectHelpers'

export type Option<T = string, Data = unknown> = {
  value: T
  label: string
  isDisabled?: boolean
  [key: string]: unknown
} & Partial<Data>
export type EventHandler<ValueT = string> = (e: {
  target: { value?: ValueT | ValueT[]; id?: string; name?: string }
}) => void

export interface SelectProps<IsMulti extends boolean, ValueT>
  extends Omit<Props<Option<ValueT>, IsMulti>, 'onChange' | 'value'> {
  label?: string | React.ReactElement
  classes?: {
    label?: string
    control?: string
  }
  required?: boolean
  errorMessage?: string
  value?: ValueT | Array<ValueT>
  onChange?: (e: { target: { value?: ValueT | ValueT[]; id?: string; name?: string } }) => void
  onCreateOption?: (value: string) => void
  tooltip?: string
  openOnRender?: boolean
  OptionComponent?: React.FC<{ option: Option<ValueT> }>
  isOnboard?: boolean
  onboardAttribute?: string
}

export const Select = function <IsMulti extends boolean = false, ValueT = string>({
  label,
  onChange,
  options = [],
  value,
  className,
  required = false,
  classes = {},
  id,
  name,
  onCreateOption,
  errorMessage,
  tooltip,
  openOnRender = false,
  isOnboard = false,
  onboardAttribute,
  OptionComponent,
  components: selectComponents = {},
  ...props
}: SelectProps<IsMulti, ValueT>) {
  const { t } = useTranslation('common')
  const ref = useRef<HTMLElement>(null)
  const styles = useMemo(() => getSelectStyles<Option<ValueT>, IsMulti>(errorMessage), [errorMessage])

  const handleMultiChange = useCallback(
    (newValue: OnChangeValue<Option<ValueT>, IsMulti>) => {
      if (onChange) {
        const value = (newValue as MultiValue<Option<ValueT>>).map((el) => el?.value)
        onChange({ target: { value: value as ValueT[], id, name } })
      }
    },
    [onChange],
  )

  const handleSingleChange = useCallback(
    (newValue: OnChangeValue<Option<ValueT>, IsMulti>) => {
      if (onChange) {
        const value = (newValue as SingleValue<Option<ValueT>>)?.value
        onChange({ target: { value: value as ValueT, id, name } })
      }
    },
    [onChange],
  )

  const handleCreateOption = (inputValue: string) => {
    if (onCreateOption) {
      onCreateOption(inputValue)
    }
  }

  const val: Option<ValueT> = useMemo(() => {
    const v = props.isMulti
      ? ((value as ValueT[]) || []).map((item) => options.find((el) => (el as Option<ValueT>).value === item))
      : options.find((el) => (el as Option<ValueT>).value === value) || null

    return v as Option<ValueT>
  }, [value, options])

  const Cmp = onCreateOption ? CreatableSelect : ReactSelect

  return (
    <div className={twMerge(cx('text-black', !errorMessage && 'mb-4', className))}>
      {label && (
        <label
          className={cx('flex items-center text-base text-black mb-0.5 break-normal whitespace-normal', classes?.label)}
        >
          {tooltip ? (
            <>
              <span className="flex items-center">
                {label}
                <QuestionMarkCircleIcon data-tooltip-id={`${name || id || 'select'}${label}`} className="h-3 ml-1" />
                <Tooltip id={`${name || id || 'select'}${label}`}>
                  <span>{tooltip}</span>
                </Tooltip>
              </span>
            </>
          ) : (
            label
          )}
          {required && <span className="text-red-500">*</span>}
        </label>
      )}
      <Cmp
        className={classes?.control}
        value={val}
        options={options}
        onChange={props.isMulti ? handleMultiChange : handleSingleChange}
        onCreateOption={handleCreateOption}
        blurInputOnSelect
        menuPlacement="auto"
        menuPortalTarget={document.body}
        openMenuOnFocus={openOnRender}
        noOptionsMessage={() => <>{t('noOptionsLabel')}</>}
        placeholder={props.placeholder || t('defaultSelectPlaceholder')}
        components={{
          Control: ({ children, ...propsControl }) => (
            <components.Control
              {...propsControl}
              innerProps={
                {
                  'data-qa': `${label || name || id}-select`,
                  'data-onboard':
                    isOnboard || onboardAttribute ? `${onboardAttribute || name || id}-select` : undefined,
                  ...propsControl.innerProps,
                } as Record<string, unknown>
              }
              className={propsControl.isDisabled ? '!bg-netural-50 !border-gray-300' : ''}
            >
              {children}
            </components.Control>
          ),
          Input: ({ children, ...propsInput }) => (
            <components.Input {...propsInput} name={name}>
              {children}
            </components.Input>
          ),
          Option: ({ children, ...propsOption }) => {
            return (
              <components.Option
                {...propsOption}
                innerProps={
                  {
                    'data-qa': `${name || id}-option`,
                    ...propsOption.innerProps,
                  } as Record<string, unknown>
                }
              >
                {OptionComponent ? <OptionComponent option={propsOption.data as Option<ValueT>} /> : children}
              </components.Option>
            )
          },
          DropdownIndicator: (props: DropdownIndicatorProps<Option<ValueT>, IsMulti>) => {
            return (
              components.DropdownIndicator && (
                <components.DropdownIndicator {...props}>
                  <IconChevronDown className="text-neutral-300" stroke={1.5} size={16} />
                </components.DropdownIndicator>
              )
            )
          },
          ClearIndicator: (props: ClearIndicatorProps<Option<ValueT>, IsMulti>) => {
            return (
              components.ClearIndicator && (
                <components.ClearIndicator {...props}>
                  <IconX className="text-neutral-300" stroke={1.5} size={16} />
                </components.ClearIndicator>
              )
            )
          },
          ...selectComponents,
        }}
        ref={
          openOnRender
            ? (el) => {
                el?.focus()
              }
            : (ref as React.MutableRefObject<null>)
        }
        {...props}
        styles={{ ...styles, ...(props.styles || {}) }}
      />
      {errorMessage && <div className="w-full mt-0.5 text-[11px] text-red-500">{errorMessage}</div>}
    </div>
  )
}

export default Select
